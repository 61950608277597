import { Layout } from '@components/layout';
import { Breadcrumbs } from '@components/shared';
import type { IPage, IPost, IProduct, ILocation } from '@standardTypes';
import React from 'react';

interface PostProps {
    pageContext: {
        page: IPage;
        pages: Array<IPage>;
        translationUrls: Object;
    };
    location: ILocation;
}

const Post: React.FC<PostProps> = ({ pageContext, location }) => {
    const { page, translationUrls } = pageContext;
    console.log('page', pageContext)

    return (
        <Layout {...page} translationUrls={translationUrls}>
            <h1>{page.title}</h1>
        </Layout>
    );
};

export default Post;
